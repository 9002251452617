<template>
  <div :class="[classes, !loading && response.data && response.data.length ? 'has-data' : 'empty-data']"
       class="table-responsive overflow-hidden text-nowrap">
    <div class="pagination-toolbar">
      <div v-if="response.total">
        <div id="total" class="total"> 全{{ response.total }}件中　{{ response.from }}件〜{{
            response.to > response.total ?
                response.total : response.to
          }}件を表示
        </div>
        <!--        <div class="goto d-none">-->
        <!--          <span>Go to page:</span>-->
        <!--          <input v-model="response.current_page" :max=response.last_page class="form-control"-->
        <!--                 min=1 type="number" @keyup="goToPage($event)" @mousedown="goToPage($event)"-->
        <!--                 @mouseup="goToPage($event)"/>-->
        <!--        </div>-->
        <div class="pagination-container mt-2">
          <div class="page-list">
            <pagination
                :data="response"
                :limit="limit"
                @pagination-change-page="setCurrentPage">
            </pagination>
          </div>
          <div id="perPage" class="per-page">
            <select id="select-up-down" v-model="response.per_page" class="form-control"
                    @change="setRowsPerPage($event)">
              <option disabled selected>表示件数</option>
              <option value=20>20</option>
              <option value=50>50</option>
              <option value=100>100</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <table class="table table-striped">
      <thead>
      <tr>
        <th v-if="serialize">
        </th>
        <th v-for="column in columns" :class="column.class" @click="sortColumn(column)">
          {{ column.label }}
          <span v-if="sortable && column.sortable">
              <i :class="column.icon"></i>
          </span>
        </th>
      </tr>
      </thead>

      <tbody>
      <tr v-if="loading">
        <td :colspan="serialize ? columns.length + 1 : columns.length" class="loader">
          <DotLoader :type="'slide'"></DotLoader>
        </td>
      </tr>
      <tr v-if="!loading && !response.data.length">
        <td :colspan="serialize ? columns.length + 1 : columns.length" class="no-data">
          {{ messages.noData }}
        </td>
      </tr>
      <tr v-for="(row, rowIndex) in response.data" v-if="!loading" @click="activateRow(rowIndex)">
        <td v-if="serialize">
          {{ response.from + rowIndex }}
        </td>
        <template v-for="column in columns">
          <td v-tooltip.top="column.tooltip ? getTooltipProperties(row[column.key]) : '' "
              :class="{ active : activeRow === rowIndex }"
              :data-animation="false">
              <span v-if="column.clickable" @click="clickThroughHandler(column.key, row)">
                <a href="javascript:void(0);">
                    <span v-if="column.tooltip">
                        <v-clamp :max-lines="column.line" autoresize v-html="row[column.key]">
                            <!-- {{ row[column.key] }} -->
                        </v-clamp>
                    </span>

                    <span v-else>
                      <span v-if="column.format && typeof column.format ==='function'"
                            v-html="column.format(row[column.key])"></span>
                      <span v-else v-html="row[column.key]"></span>
                    </span>
                </a>
              </span>
            <span v-else>
                <span v-if="column.format && typeof column.format ==='function'"
                      v-html="column.format(row[column.key])"></span>
                <span v-else>
                  <span v-if="column.tooltip">
                    <v-clamp :max-lines="column.line" autoresize>
                        {{ row[column.key] }}
                    </v-clamp>
                  </span>
                  <span v-else>{{ row[column.key] }}</span>
                </span>
              </span>
          </td>
        </template>
      </tr>
      </tbody>
    </table>
  </div>

</template>

<script>
import pagination from 'laravel-vue-pagination'
import DotLoader from '../loaders/DotLoader'
import VClamp from 'vue-clamp'
import {messages} from '@/constants'

export default {
  name: "Datatable",
  components: {
    pagination,
    DotLoader,
    VClamp
  },
  data() {
    return {
      valueIndex: '',
      itemSort: [],
      orderArray: [],
      iconKey: {},
      activeRow: -1,
      messages: messages.datatable,
      sort: '',
      order: 'desc',
      dataInitialized: false
    }
  },
  props: {
    classes: {type: String, default: 'mt-3 mb-5'},
    loading: {type: Boolean, default: true},
    serialize: {type: Boolean, default: false},
    response: {type: Object, default: null},
    columns: {type: Array, default: null},
    sortable: {type: Boolean, default: true},
    limit: {type: Number, default: 3},
    line: {type: Number, default: 1},
    getData: {type: Function, default: null},
  },
  created() {
    this.initialize(this.response.data)
  },
  watch: {
    'response.data': {
      handler: function (data, oldData) {
        // console.log(data)
        this.initialize(data)
        this.setupInitialSort()
      },
      deep: true
    },
  },
  methods: {
    initialize(data) {
      try {
        if (data && typeof data !== 'undefined') {
          for (var k = 0; k < this.columns.length; k++) {
            this.columns[k].type = typeof data[0][this.columns[k].key]
            // console.log(typeof data[0][this.columns[k].key])
            if (!this.columns[k].hasOwnProperty('direction'))
              this.columns[k].direction = null
            if (!this.columns[k].hasOwnProperty('icon'))
              this.columns[k].icon = 'fa fa-sort ' + (this.columns[k].type === 'number' ? 'numeric' : 'alpha')
            if (!this.columns[k].hasOwnProperty('sortable'))
              this.columns[k].sortable = this.sortable
            if (!this.columns[k].hasOwnProperty('line'))
              this.columns[k].line = this.line
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    snakeToCamel(str) {
      return str.replace(
          /([-_][a-z])/g,
          (group) => group.toUpperCase()
              .replace('-', '')
              .replace('_', '')
      );
    },
    getTooltipProperties(msg) {
      return {
        content: msg,
        classes: ['info'],
        targetClasses: ['it-has-a-tooltip']
      }
    },
    setCurrentPage(page) {
      this.response.current_page = page
      this.getData()
    },
    goToPage(event) {
      var toPage = event.target.value
      if (toPage < 1) {
        this.response.current_page = 1

      } else if (toPage > this.response.last_page) {
        this.response.current_page = this.response.last_page

      }
      this.getData()
    },
    activateRow(row) {
      this.activeRow = row;
    },
    setRowsPerPage(event) {

      this.response.per_page = event.target.value
      this.response.current_page = Math.ceil(this.response.from / this.response.per_page)
      this.getData()
    },
    clickThroughHandler(key, row) {
      this.$emit('cta', key, row)
    },
    setupInitialSort() {
      if (!this.dataInitialized) {
        const sort = this.response.sort
        const order = this.response.order
        if (sort.length > 0 && order.length > 0) {
          const columnIndex = this.columns.findIndex((item) => item.key === sort)
          if (columnIndex >= 0) {
            if (this.sortColumn(this.columns[columnIndex], order, true)) {
              this.dataInitialized = true
            }
          }
        }
      }
    },
    sortColumn(column, forceOrder = null, isNoUpdate = false) {
      if (!this.sortable)
        return false
      else if (!column.sortable)
        return false
      var key = column.key
      var orderArray = [];
      for (var k = 0; k < this.columns.length; k++) {
        orderArray[k] = this.columns[k].key
      }
      var order2DArray = []
      for (var k = 0; k < orderArray.length; k++) {
        order2DArray[orderArray[k]] = null
      }
      order2DArray = this.orderArray
      var expectedString = ''
      const obj = Object.fromEntries(order2DArray);
      if (forceOrder !== null) {
        obj[key] = forceOrder
      } else {
        if (obj[key] == null) {
          obj[key] = 'asc'
        } else if (obj[key] === 'asc') {
          obj[key] = 'desc'
        } else if (obj[key] === 'desc') {
          obj[key] = 'asc'
        } else if (!(key in obj)) {
          obj[key] = 'asc'
        }
      }
      var previousArrayItems = []
      var previousArray = Object.entries(obj)
      this.orderArray = previousArray
      var filteredObject = Object.fromEntries(previousArray.filter(([key, value]) => key === column.key))
      var sort = this.snakeToCamel(Object.keys(filteredObject).toString())
      var order = filteredObject[Object.keys(filteredObject).toString()]
      order = typeof order === 'undefined' ? null : order
      this.response.sort = sort
      this.response.order = order
      if (isNoUpdate === false) {
        this.getData()
      }
      this.setIcon(column, order)
      // previousArray.sort((a, b) => this.itemSort.indexOf(a[0]) - this.itemSort.indexOf(b[0]));
      // for (var i = 0; i < previousArray.length; i++) {
      //   previousArrayItems = previousArrayItems.concat(previousArray[i]);
      // }
      // this.itemSort = orderArray
      // for (var j = 0; j < previousArrayItems.length; j++) {
      //   if (previousArrayItems[j] === 'asc' || previousArrayItems[j] === 'desc' || previousArrayItems[j] == null) {
      //     previousArrayItems.splice(j + 1, 0, ",");
      //     j++;
      //   } else {
      //     previousArrayItems.splice(j + 1, 0, ":")
      //     j++;
      //   }
      // }
      // previousArrayItems.pop(previousArrayItems.length - 1)
      // var keyIndex = previousArrayItems.indexOf(key)
      // var valueIndex = keyIndex + 2;
      //
      // this.valueIndex = previousArrayItems[valueIndex] || null
      //
      // expectedString = previousArrayItems.join('')
      // this.response.sort = expectedString
      // this.getData()
      //this.setIcon(column, this.valueIndex)
    },
    setIcon(column, direction) {
      this.columns.map(column => {
        column.icon = 'fas fa-sort'
      })
      for (var i = 0; i < this.columns.length; i++) {
        if (this.columns[i].key === column.key /*&& column.sortable*/) {
          // console.log(this.columns[i].key, direction)
          // console.log(this.columns,column)

          // const sortType = column.type === 'number' ? 'numeric' : 'alpha'
          // console.log(column.type, column.type === 'number', sortType)
          if (direction !== null) {
            this.columns[i].icon = `fas fa-sort-${column.type === 'number' ? 'numeric' : 'alpha'}-${direction === 'asc' ? 'down' : 'down-alt'}`
          } else {
            this.columns[i].icon = 'fas fa-sort ' + column.type
          }

        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  font-size: 14px;
  color: inherit; // default: #555555
  background-color: #ffffff;
  border-color: #707070; // default: #E0E0E0

  -moz-border-top-left-radius: 3px;
  -moz-border-top-right-radius: 3px;
  -webkit-border-top-left-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;


  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -ms-touch-action: none;
  -moz-background-clip: padding;
  -webkit-text-size-adjust: none;
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-style: normal;

  zoom: 1 !important;
  -ms-text-size-adjust: none;
  -webkit-appearance: none;
  direction: ltr !important;

  table-layout: fixed;
  margin: 0;
  padding: 0;
  border-collapse: separate;
  border-spacing: 0;
  -webkit-overflow-scrolling: touch;
  outline: none;
  empty-cells: show;
  border: 1px solid #707070; // default: #E0E0E0
  line-height: 1.231;
  width: 100%;

  tr {
    th, td {
      text-align: center;

      &:first-child {
        border-left-width: 0;
      }
    }

    th {
      border-color: #707070; // default: #E0E0E0
      border-width: 0 0 1px 1px;
      border-style: solid;
      position: relative;

      &:hover span {
        border-left: 1px solid #f3f3f3 !important;

      }

      span {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        width: 24px;
        height: 100%;
        padding: 12px 0;
        border-left: 1px solid transparent;

        i.fa-sort {
          color: #909090;
        }

        i:not(.fa-sort) {
          right: 1px;
        }

        &:hover {
          i.fa-sort-numeric {
            &-down:before {
              content: "\F163";
            }
          }

          i.fa-sort-alpha {
            &-down:before {
              content: "\F15E";
            }

            &-down-alt:before {
              /*content: "\F0DC";*/
            }
          }
        }

      }
    }

    &:nth-of-type(odd) td {
      background-color: #fff; //default: #ecebeb
    }

    /*&:hover td {*/
    /*    border-color: rgba(255, 255, 255, 0.25); //default: rgba(255, 255, 255, 0.25)*/
    /*    background-color: #fff;  //default: #dedede*/
    /*    border-bottom: 1px solid black;*/
    /*    border-right: 1px solid #1b1e21;*/
    /*}*/

    &:last-child td {
      border-bottom-width: 0;
    }

    td {
      margin: 0;
      border-collapse: separate;
      border-spacing: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      border: 1px solid transparent;
      border-top-width: 0;
      border-left-width: 0;
      white-space: normal;
      line-height: 1.231;

      border-color: #707070; // default: #E0E0E0
      border-width: 0 0 1px 1px;
      position: static !important;
      zoom: 1 !important;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      outline: 0;

      &.selected, &.active, &:active {
        /*color: #0077BE !important;*/
        //default co0lor #0077BE
        /*border-color: #E1F5FE !important;*/
        //default co0lor #E1F5FE
        /*background: #E1F5FE;*/
        //default co0lor #E1F5FE
        /*box-shadow: none;*/
        /*border-left: 0 solid transparent;*/

      }
    }
  }
}


.pagination {
  margin-bottom: 0;

  &-toolbar {
    width: 100%;
    display: block;
    overflow: auto;
    font-size: 13px;
    color: #333333; // default: #555555
    background-color: #ffffff;
    border-color: #707070; // default: #E0E0E0

    -moz-border-bottom-left-radius: 3px;
    -moz-border-bottom-right-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-width: 0 1px 1px 1px;
    border-style: solid;
  }

  &-container {
    display: inline-flex;
    float: right;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 8px;
    line-height: 24px;

    > div {
      display: inline-flex;

      &:not(:first-child) {
        margin-left: 15px;
      }

      span {
        width: 75px;
      }

      input, select {
        width: 75px;
      }
    }

    .goto {

    }

  }

  @media only screen and (max-width: 1000px) {
    &-container {
      display: flex;
      float: left;
      padding-left: 12px;
    }
  }

  &-toolbar .form-control {
    background-color: transparent !important;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;

    height: auto;
    padding: 0 0 !important;
    text-align: right;
    border-width: 0 0 1.25px 0;
    border-radius: 0;

  }
}

.loader, .no-data {
  height: 300px;
  vertical-align: middle;
}

.loader {
  background-color: #ffffff !important;
}

@media only screen and (max-width: 939px) {
  /*#total {*/
  /*  display: none;*/
  /*}*/
}

.empty-data {
  table {
    border-bottom: 0;
  }

  .pagination-toolbar {
    //height: 4px;
  }
}

// Customize pagination toolbar
.pagination {
  &-toolbar {
    border: none;
    margin-bottom: 20px;
  }

  &-container {
    float: left;
    width: 100%;
    display: block;
    padding: 0;

    .total {
      color: #333333;
    }

    .per-page {
      float: right;
    }

    #select-up-down.form-control {
      background-image: url('../../assets/images/sort.png');
      background-position: calc(100% - 3px) 50%;
      background-repeat: no-repeat;
      background-size: 13px 13px;
      min-width: 94px;
      text-align: center;
    }

    .form-control {
      border: 1px solid #DEE2E6 !important;
      border-radius: 4px;
      height: 32px !important;
      padding-left: 10px !important;
    }
  }
}

table {
  border-color: #F2ECF0 !important;
  border-radius: 4px !important;

  tr {
    &:last-child {
      &, td {
        border-bottom-width: 0 !important;
      }
    }

    th, td {
      border-color: #F2ECF0 !important;
      border-width: 0 0 1px 0 !important;
      vertical-align: middle;
      padding: 1px;
    }

    th {
      height: 56px;

      &:hover span, span {
        border: 0 !important;
      }

      span {
        position: relative;
        padding: 18px 0;
      }
    }

    td {
      //height: 52px;
      padding-top: 11.5px;
      padding-bottom: 11.5px;
    }
  }
}
</style>